<template>
                 <div class="col-xl-6 xl-100 box-col-12">
                <div class="card">
                  <div class="card-header pb-0 d-flex justify-content-between align-items-center">
                    <h5>PRODUCTS CART</h5>
                  </div>
                  <div class="card-body">
                    <div class="user-status table-responsive">
                      <table class="table table-bordernone">
                        <thead>
                          <tr>
                            <th scope="col">Details</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Status</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="f-w-600 simply ">Men Casual Shirt</td>
                            <td>1</td>
                            <td class="font-primary">Pending</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-secondary">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Rayon Women Regular Top</td>
                            <td>5</td>
                            <td class="font-secondary">Cancle</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-success">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Flower Printed Dress</td>
                            <td>10</td>
                            <td class="font-secondary">Cancle</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-warning">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Polyester Women Dress</td>
                            <td>9</td>
                            <td class="font-primary">Return</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-primary">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Cotton Regular T-Shirt</td>
                            <td>8</td>
                            <td class="font-primary">Pending</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-danger">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Printed Sleev Dress</td>
                            <td>3</td>
                            <td class="font-secondary">Cancle</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-info">6523</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="f-w-600 simply ">Rayon Women Regular Top</td>
                            <td>8</td>
                            <td class="font-primary">Return</td>
                            <td>
                              <div class="span badge rounded-pill pill-badge-primary">6523</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                   
                  </div>
                </div>
              </div>
</template>
<script>
export default {
    
}
</script>