<template>
     <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Headings</h5><span>All HTML headings, <code>&lt;h1&gt;</code> through <code>&lt;h6&gt;</code>, are available.</span>
                  </div>
                  <div class="card-body typography">
                        <h1 class="txt-primary">This is a Heading 1</h1>
                        <h2 class="txt-secondary">This is a Heading 2</h2>
                        <h3 class="txt-success">This is a Heading 3</h3>
                        <h4 class="txt-info">This is a Heading 4</h4>
                        <h5 class="txt-warning">This is a Heading 5</h5>
                        <h6 class="txt-danger">This is a Heading 6</h6>
                  </div>
                </div>
     </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header pb-0">
          <h5>Headings with font weight</h5>
        </div>
        <div class="card-body typography">
          <h3 class="f-w-100">Very Thin (100)</h3>
          <h3 class="f-w-300">Thin (300)</h3>
          <h3 class="f-w-400">Normal (400)</h3>
          <h2 class="f-w-600">Light Normal (600)</h2>
          <h2 class="f-w-700">Bold (700) </h2>
          <h2 class="f-w-900">Extra Bold (900)</h2>
        </div>
      </div>
    </div>

</template>