<template>
  <div class="container-fluid p-0 ">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <!-- <a class="logo">
              <div class="logo-wrapper">
                <img
                  class="img-fluid for-light"
                  src="../assets/images/logo/logo.png"
                  alt="looginpage"
                />
              </div>
            </a> -->
          </div>
          <form class="theme-form login-form">
            <h4>Login</h4>
            <h6>Welcome back! Log in to your account.</h6>
            <div class="form-group">
              <label>Email Address</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-email"></i></span>
                <input v-model="email" class="form-control" type="email" required="" placeholder="Test@gmail.com"
                  :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !email" class="invalid-feedback">
                  Email is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-lock"></i></span>
                <input v-model="password" autocomplete="" class="form-control" :type="type" name="login[password]"
                  required="" placeholder="*********" :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !password" class="invalid-feedback">
                  Email is required
                </div>
                <div class="show-hide">
                  <span class="show" @click="showPassword"></span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox p-0 mb-3 w-100">
                <input id="checkbox1" type="checkbox" />
                <label class="text-muted" for="checkbox1">Remember password</label>
                <a class="link" href="Forget Password">Forgot password?</a>
              </div>
              <button class="btn btn-primary btn-block" type="button" @click="signUp">
                Login
              </button>
            </div>
            <div class="login-social-title">
              <h5>Sign in with</h5>
            </div>
            <div class="form-group">
              <ul class="login-social">
                <li>
                  <a href="" @click="socialLogin" target="_blank"><i class="fa fa-google"></i></a>
                </li>
                <li>
                  <a href="" @click="socialLoginTwitter" target="_blank"><i data-feather="twitter"><vue-feather
                        type="twitter"></vue-feather></i></a>
                </li>
                <li>
                  <a href="" @click="socialLoginFacebook" target="_blank"><i data-feather="facebook"><vue-feather
                        type="facebook"></vue-feather></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/login" target="_blank"><i data-feather="instagram"><vue-feather
                        type="instagram"></vue-feather></i></a>
                </li>
              </ul>
            </div>
            <p class="mt-4 mb-0">
              Don't have account?<router-link class="ms-2" tag="a" to="/auth/register">
                Create Account</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase';
import Userauth from '../auth/js/index';

export default {
  name: 'login',
  data() {
    return {
      type: 'password',
      email: 'test@admin.com',
      password: 'test@123456',
      username: '',
      passwordjwt: '',
      submitted: false,
    };
  },
  computed: {

    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {

    this.$store.dispatch('authentication/logout');
  },
  methods: {

    handleSubmit() {
      this.submitted = true;
      const { username, passwordjwt } = this;
      const { dispatch } = this.$store;
      if (username && passwordjwt) {
        dispatch('authentication/login', { username, passwordjwt });
      }
    },

    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    signUp: function () {
      this.submitted = true;
      if (this.email === '' && this.password === '') {
        (this.email = 'test@admin.com'), (this.password = 'test@123456');
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            (result) => {
              Userauth.localLogin(result);
              this.$router.replace('/');
            },
            (err) => {
              (this.email = 'test@admin.com'), (this.password = 'test@123456');
              this.$toast.show('Oops...' + err.message, {
                theme: 'bubble',
                position: 'bottom-right',
                type: 'error',
                duration: 2000,
              });
            }
          );
      }
    },

    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace('/');
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    },
    socialLoginFacebook() {
      const provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace('/');
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    },
    socialLoginTwitter() {
      const provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace('/');
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    },
    socialLoginGit() {
      const provider = new firebase.auth.GithubAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace('/');
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    },

    login() {
      Userauth.login();
    },
  },
};
</script>
