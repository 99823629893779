<template>
      <div class="card mb-0">
                              <div class="card-header d-flex">
                                <h6 class="mb-0">Notification</h6>
                                <ul>
                                  <li><a class="grid-bookmark-view" href="#"><i data-feather="grid"><vue-feather type="grid"></vue-feather></i></a></li>
                                  <li><a class="list-layout-view" href="#"><i data-feather="list"><vue-feather type="list"></vue-feather></i></a></li>
                                </ul>
                              </div>
                              <div class="card-body">
                                <div class="details-bookmark text-center"><span>No Bookmarks Found.</span></div>
                              </div>
                            </div>
</template>
<script>
export default {
    
}
</script>