<template>
     <div class="card comment-box">
                    <div class="card-body">
                      <h4>Comment</h4>
                      <ul> <li>
                          <div class="media align-self-center"><img class="align-self-center" src="../../../assets/images/blog/comment.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-xl-5"><a href="#"><router-link to="/users/profile">
                                    <h6 class="mt-0">Kerly Mark<span> ( Designer )</span></h6></router-link></a></div>
                                <div class="col-xl-7">
                                  <ul class="comment-social float-start float-xl-end">
                                    <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                    <li><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>The best thing is location and drive through the forest. The resort is 35km from Ramnagar. The gardens are well kept and maintained. Its a good place for relaxation away from the city noise. The staff is very friendly and overall we had a really good & fun time, thanks to staff member - Bhairav, Rajat, Gunanand, Lokesh & everyone else. And also we went for an adventurous night safari and saw barking deers, tuskar elephant.</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <ul>
                            <li>
                              <div class="media"><img class="align-self-center" src="../../../assets/images/blog/9.jpg" alt="Generic placeholder image">
                                <div class="media-body">
                                  <div class="row">
                                    <div class="col-xl-12"><a href=""><router-link to="/users/profile">
                                        <h6 class="mt-0">Jolio Mark<span> ( Designer )</span></h6></router-link></a></div>
                                  </div>
                                  <p>Clean resort with maintained garden but rooms are average Lack of communication between the staff members. Receptionsit full of attitude. Arrogant staff. Except good view there is nothing great in this property.Resort is 35 kms away from Ramnagar Town.</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="media"><img class="align-self-center" src="../../../assets/images/blog/4.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-xl-5"><a href=""><router-link to="/users/profile">
                                    <h6 class="mt-0">Genelia Lio<span> ( Designer )</span></h6></router-link></a></div>
                                <div class="col-xl-7">
                                  <ul class="comment-social float-start float-xl-end">
                                    <li><i class="icofont icofont-thumbs-up"></i>52 Hits</li>
                                    <li><i class="icofont icofont-ui-chat"></i>98 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>Clean resort with maintained garden but rooms are average Lack of communication between the staff members. Receptionsit full of attitude. Arrogant staff. Except good view there is nothing great in this property.Resort is 35 kms away from Ramnagar Town.</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="media"><img class="align-self-center" src="../../../assets/images/blog/12.png" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-xl-5"><a href=""><router-link to="/users/profile">
                                    <h6 class="mt-0">Kerly Mark<span> ( Designer )</span></h6></router-link></a></div>
                                <div class="col-xl-7">
                                  <ul class="comment-social float-start float-xl-end">
                                    <li><i class="icofont icofont-thumbs-up"></i>28 Hits</li>
                                    <li><i class="icofont icofont-ui-chat"></i>97 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>Clean resort with maintained garden but rooms are average Lack of communication between the staff members. Receptionsit full of attitude. Arrogant staff. Except good view there is nothing great in this property.Resort is 35 kms away from Ramnagar Town.</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="media"><img class="align-self-center" src="../../../assets/images/blog/14.png" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-xl-5"><a href=""><router-link to="/users/profile">
                                    <h6 class="mt-0 ">Robbert Deo<span> ( Designer )</span></h6></router-link></a></div>
                                <div class="col-xl-7">
                                  <ul class="comment-social float-start float-xl-end">
                                    <li><i class="icofont icofont-thumbs-up"></i>52 Hits</li>
                                    <li><i class="icofont icofont-ui-chat"></i>58 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>Clean resort with maintained garden but rooms are average Lack of communication between the staff members. Receptionsit full of attitude. Arrogant staff. Except good view there is nothing great in this property.Resort is 35 kms away from Ramnagar Town.</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
</template>