<template>
    <div>
        <div class="card">
                  <div class="card-header pb-0">
                    <h5>Tooltips</h5><span>If your form layout allows it, you can swap the <code class="text-danger">.{valid|invalid}-feedback</code> classes for <code class="text-danger">.{valid|invalid}-tooltip</code> classes to display validation feedback in a styled tooltip. Be sure to have a parent with <code class="text-danger">position: relative</code> on it for tooltip positioning. In the example below, our column classes have this already, but your project may require an alternative setup.</span>
                  </div>
                  <div class="card-body">
                    <form class="row g-3 needs-validation" novalidate="" @submit.prevent="onCustomStyleSubmit">
                      <div class="col-md-4 position-relative">
                        <label class="form-label" for="validationTooltip01">First name</label>
                         <input type="text" class="form-control"   v-bind:class=" formSubmitted ? firstError ? 'is-invalid' : 'is-valid' : '' " id="name" placeholder="Your name" v-model="firstname">
                        <div class="valid-tooltip" v-if="errors[0]">{{ errors[0].message }}</div>
                      </div>
                      <div class="col-md-4 position-relative">
                        <label class="form-label" for="validationTooltip02">Last name</label>
                       <input class="form-control"  type="text" v-bind:class=" formSubmitted ? lastError ? 'is-invalid' : 'is-valid' : '' " id="name" placeholder="Your name" v-model="lastname">
                         <div class="valid-tooltip" v-if="errors[0]">{{ errors[0].message }}</div>
                      </div>
                      <div class="col-md-4 position-relative">
                        <label class="form-label" for="validationTooltipUsername">Username</label>
                        <div class="input-group"><span class="input-group-text" id="validationTooltipUsernamePrepend">@</span>
                           <input class="form-control" id="validationCustomUsername" type="text" v-bind:class=" formSubmitted ? userError ? 'is-invalid' : 'is-valid' : '' " placeholder="Username" v-model="username" >
                          <div class="invalid-tooltip"  v-if="errors[1]">{{ errors[1].message1 }}</div>
                        </div>
                      </div>
                      <div class="col-md-6 position-relative">
                        <label class="form-label" for="validationTooltip03">City</label>
                        <input class="form-control" id="validationCustom03" type="text" v-bind:class=" formSubmitted ? cityError ? 'is-invalid' : 'is-valid' : '' " placeholder="City" required="" v-model="city">
                        <div class="invalid-tooltip" v-if="errors[1]">{{ errors[1].message2 }}</div>
                      </div>
                      <div class="col-md-3 position-relative">
                        <label class="form-label" for="validationTooltip04">State</label>
                        <input class="form-control" id="validationTooltip04" type="text" v-bind:class=" formSubmitted ? cityError ? 'is-invalid' : 'is-valid' : '' " placeholder="State" required="">
                        <div class="invalid-tooltip" v-if="errors[1]">{{ errors[1].message3 }}</div>
                      </div>
                      <div class="col-md-3 position-relative">
                        <label class="form-label" for="validationTooltip05">Zip</label>
                         <input class="form-control" id="validationCustom05" type="text" v-bind:class=" formSubmitted ? zipError ? 'is-invalid' : 'is-valid' : '' "  v-model="zip" placeholder="Zip" required="">
                        <div class="invalid-tooltip" v-if="errors[1]">{{ errors[1].message4 }}</div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary" type="submit">Submit form</button>
                      </div>
                    </form>
                  </div>
                </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
          errors: [],
          formSubmitted:false,
          firstname:"mark",
          firstError:false,
          lastname:"Otto",
          lastError:false,
          username:"",
          userError:false,
          city:"",state:"",zip:'',
          cityError:false,
          stateError:false,
          zipError:false,
   }
    },
        methods:{
      onCustomStyleSubmit(){ 
         this.formSubmitted = true; 
        this.errors = [];          
				if (this.firstname.length<3||this.firstname>10 && this.lastname.length<3 || this.lastname.length>10  ) {
					this.firstError = true;
          this.lastErrorl=true;
					this.errors.push({      	
					});
				} else {
				this.firstError = false;
        this.lastError=false;
					this.errors.push({
						'message': 'Look good!'
					});
				}
        if(this.username.length<3|| this.username.length>6 && this.city.length<3|| this.city.length>10 && this.state.length<7 && this.zip<8 ){
          this.userError=true;
          this.cityError=true;
          this.stateError=true;
          this.zipError=true;
          this.errors.push({     
             	'message1': 'Please choose a unique and valid username.', 'message2': 'Please provide a valid city.', 'message3': 'Please select a valid state.', 'message4': 'Please provide a valid zip.',
					});
        }
        else{
          this.userError=false;
          this.cityError=false;
          this.stateError=false;
          this.zipError=false;
        }
      },
      
        }
}
</script>