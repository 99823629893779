<template>
    <div class="col-xl-4 xl-40 col-lg-6 col-md-5 box-col-4">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card card-mb-faq xs-mt-search">
                            <div class="card-header faq-header">
                              <h5 class="d-inline-block ps-0">Search articles</h5><vue-feather type="help-circle"></vue-feather>
                            </div>
                            <div class="card-body faq-body">
                              <div class="faq-form">
                                <input class="form-control" type="text" placeholder="Search.."><vue-feather type="search" class="search-icon"></vue-feather>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="card card-mb-faq">
                            <div class="card-header faq-header">
                              <h5 class="d-inline-block ps-0">Navigation</h5><vue-feather type="settings"></vue-feather>
                            </div>
                            <div class="card-body faq-body">
                              <div class="navigation-btn"><a class="btn btn-primary" href="javascript:void(0)"><vue-feather class="m-r-10"  type="message-square"></vue-feather>Ask Question</a></div>
                              <div class="navigation-option">
                                <ul>
                                  <li><a href="javascript:void(0)"><vue-feather type="edit"></vue-feather>Tutorials</a></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="globe"></vue-feather>Help center</a></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="book-open"></vue-feather>Knowledgebase</a></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="file-text"></vue-feather>Articles</a><span class="badge badge-primary rounded-pill pull-right">42</span></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="youtube"></vue-feather>Video Tutorials</a><span class="badge badge-primary rounded-pill pull-right">648</span></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="message-circle"></vue-feather>Ask our community</a></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="mail"></vue-feather>Contact us</a></li>
                                </ul>
                                <ul>
                                  <li><a href="javascript:void(0)"><vue-feather type="message-circle"></vue-feather>Ask our community</a></li>
                                  <li><a href="javascript:void(0)"><vue-feather type="mail"></vue-feather>Contact us</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-header faq-header">
                              <h5 class="d-inline-block ps-0">Latest Updates</h5><span class="pull-right d-inline-block">See All</span>
                            </div>
                            <div class="card-body faq-body">
                              <div class="media updates-faq-main">
                                <div class="updates-faq"><vue-feather  class="font-primary" type="rotate-cw"></vue-feather></div>
                                <div class="media-body updates-bottom-time">
                                  <p><a href="javascript:void(0)">David Linner </a>requested money back for a double debit card charge</p>
                                  <p>10 minutes ago</p>
                                </div>
                              </div>
                              <div class="media updates-faq-main">
                                <div class="updates-faq"><vue-feather class="font-primary" type="dollar-sign"></vue-feather></div>
                                <div class="media-body updates-bottom-time">
                                  <p>All sellers have received monthly payouts</p>
                                  <p>2 hours ago</p>
                                </div>
                              </div>
                              <div class="media updates-faq-main">
                                <div class="updates-faq"><vue-feather class="font-primary" type="link"></vue-feather></div>
                                <div class="media-body updates-bottom-time">
                                  <p>User Christopher <a href="javascript:void(0)">Wallace</a> is on hold and awaiting for staff reply</p>
                                  <p>45 minutes ago</p>
                                </div>
                              </div>
                              <div class="media updates-faq-main">
                                <div class="updates-faq"><vue-feather class="font-primary" type="check"></vue-feather></div>
                                <div class="media-body updates-bottom-time">
                                  <p>Ticket #43683 has been closed by <a href="javascript:void(0)">Victoria Wilson</a></p>
                                  <p>Dec 7, 11:48</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
</template>