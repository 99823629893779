<template>
    		<Breadcrumbs main="Learning" title="Detailed Course"/>
            <div class="container-fluid">
            <div class="row learning-block">
             <div class="col-xl-9 xl-60 box-col-8">
                <div class="blog-single">
                  <div class="blog-box blog-details">
                    <div class="card"><img class="img-fluid w-100" src="../../assets/images/faq/learning-1.jpg" alt="blog-main"></div>
                    <div class="card">
                      <div class="card-body">
                        <div class="blog-details">
                          <ul class="blog-social">
                            <li class="digits">25 July 2023</li>
                            <li><i class="icofont icofont-user"></i>Mark <span>Jecno </span></li>
                            <li class="digits"><i class="icofont icofont-thumbs-up"></i>02<span>Hits</span></li>
                            <li class="digits"><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                          </ul>
                          <h4>Knowledge can be defined as awareness of facts or as practical skills. there is wide agreement among philosophers that it is a form of true belief.</h4>
                          <div class="single-blog-content-top">
                            <p>Knowledge can be defined as awareness of facts or as practical skills, and may also refer to familiarity with objects or situations. Knowledge of facts, also called propositional knowledge, is often defined as true belief that is distinct from opinion or guesswork by virtue of justification. While there is wide agreement among philosophers that it is a form of true belief, many controversies in philosophy focus on justification: whether it is needed at all, how to understand it, and whether something else besides it is needed.</p>
                            <p>Knowledge can be produced in many different ways. The most important source is perception, which refers to the usage of the five senses. Many theorists also include introspection as a source of knowledge, not of external physical objects, but of one's own mental states. Other sources often discussed include memory, rational intuition, inference, and testimony. According to foundationalism, some of these sources are basic in the sense that they can justify beliefs without depending on other mental states. This claim is rejected by coherentists.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div class="card comment-box">
                    <div class="card-body">
                      <h4>Comment</h4>
                      <ul>
                        <li>
                          <div class="media align-self-center"><img class="align-self-center" src="../../assets/images/blog/comment.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-md-4 xl-100 box-col-12"><a href="user-profile.html">
                                    <h6 class="mt-0">Kerly Mark<span> ( Designer )</span></h6></a></div>
                                <div class="col-md-8 xl-100 box-col-12">
                                  <ul class="comment-social learning-comment">
                                    <li class="digits"><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                    <li class="digits"><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>The best thing is location and drive through the forest. The resort is 35km from Ramnagar. The gardens are well kept and maintained. Its a good place for relaxation away from the city noise. The staff is very friendly and overall we had a really good & fun time, thanks to staff member - Bhairav, Rajat, Gunanand, Lokesh & everyone else. And also we went for an adventurous night safari and saw barking deers, tuskar elephant.</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <ul>
                            <li>
                              <div class="media"><img class="align-self-center" src="../../assets/images/blog/9.jpg" alt="Generic placeholder image">
                                <div class="media-body">
                                  <div class="row">
                                    <div class="col-xl-12"><a href="user-profile.html">
                                        <h6 class="mt-0">Robbert Deo<span> ( Designer )</span></h6></a></div>
                                  </div>
                                  <p>The staff is very friendly and overall we had a really good & fun time, thanks to staff member - Bhairav, Rajat, Gunanand, Lokesh & everyone else. And also we went for an adventurous night safari and saw barking deers, tuskar elephant.</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div class="media"><img class="align-self-center" src="../../assets/images/blog/4.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                              <div class="row">
                                <div class="col-md-4 xl-100 box-col-12"><a href="user-profile.html">
                                    <h6 class="mt-0">Genel Lio<span> ( Designer )</span></h6></a></div>
                                <div class="col-md-8 xl-100 box-col-12">
                                  <ul class="comment-social learning-comment">
                                    <li class="digits"><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                    <li class="digits"><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                                  </ul>
                                </div>
                              </div>
                              <p>The resort is 35km from Ramnagar. The gardens are well kept and maintained. Its a good place for relaxation away from the city noise. The staff is very friendly and overall we had a really good & fun time, thanks to staff member</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
             <div class="col-xl-3 xl-40">
              <div class="job-sidebar"><a class="btn btn-primary job-toggle" href="javascript:void(0)" @click="collapseFilter()">learning filter</a>
                  <div class="job-left-aside custom-scrollbar custom-scrollbar" :class="filtered?'open':''">
                    <div class="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                      <div class="row">
                          <learningtab/>
                      </div>
                    </div>
                  </div>
                  </div>
             </div>
            </div>
            </div>
          
</template>
<script>
import learningtab from "./learninglist/learningtab.vue"
export default {
    components:{
       learningtab
    },
    data(){
      return {
        filtered: false,
      };
    },
   
    methods:{
	  collapseFilter() {
        this.filtered = !this.filtered;
      },
    }
}
</script>