<template>
     <div class="card">
                  <div class="card-header pb-0">
                    <h5>outline buttons</h5>
                   <span>Add <code>.btn-pill</code> and <code>.btn-outline-*</code> class for button with outline</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary" type="button">Primary Button</button>
                    <button class="btn btn-pill btn-outline-secondary" type="button">Secondary Button</button>
                    <button class="btn btn-pill btn-outline-success" type="button">Success Button</button>
                    <button class="btn btn-pill btn-outline-info" type="button">Info Button</button>
                    <button class="btn btn-pill btn-outline-warning" type="button">Warning Button</button>
                    <button class="btn btn-pill btn-outline-danger" type="button">Danger Button</button>
                    <button class="btn btn-pill btn-outline-light txt-dark" type="button">Light Button</button>
                   
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>bold Border outline buttons</h5>
                    <span>Add <code>.btn-pill</code> and <code>.btn-outline-*-2x</code> class for button with bold outline</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary-2x" type="button">Primary Button</button>
                    <button class="btn btn-pill btn-outline-secondary-2x" type="button">Secondary Button</button>
                    <button class="btn btn-pill btn-outline-success-2x" type="button">Success Button</button>
                    <button class="btn btn-pill btn-outline-info-2x" type="button">Info Button</button>
                    <button class="btn btn-pill btn-outline-warning-2x" type="button">Warning Button</button>
                    <button class="btn btn-pill btn-outline-danger-2x" type="button">Danger Button</button>
                    <button class="btn btn-pill btn-outline-light-2x txt-dark" type="button">Light Button</button>
                    
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>outline Large buttons</h5>
                    <span>Add <code>.btn-pill</code>,<code>.btn-outline-*</code> and <code>.btn-lg</code> class for large button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary btn-lg" type="button">Primary Button</button>
                    <button class="btn btn-pill btn-outline-secondary btn-lg" type="button">Secondary Button</button>
                    <button class="btn btn-pill btn-outline-success btn-lg" type="button">Success Button</button>
                    <button class="btn btn-pill btn-outline-info btn-lg" type="button">Info Button</button>
                    <button class="btn btn-pill btn-outline-warning btn-lg" type="button">Warning Button</button>
                    <button class="btn btn-pill btn-outline-danger btn-lg" type="button">Danger Button</button>
                    <button class="btn btn-pill btn-outline-light btn-lg txt-dark" type="button">Light Button</button>
                    
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>outline small buttons</h5>
                    <span>Add <code>.btn-pill</code>,<code>.btn-outline-*</code> and <code>.btn-sm</code> class for small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary btn-sm" type="button">Primary Button</button>
                    <button class="btn btn-pill btn-outline-secondary btn-sm" type="button">Secondary Button</button>
                    <button class="btn btn-pill btn-outline-success btn-sm" type="button">Success Button</button>
                    <button class="btn btn-pill btn-outline-info btn-sm" type="button">Info Button</button>
                    <button class="btn btn-pill btn-outline-warning btn-sm" type="button">Warning Button</button>
                    <button class="btn btn-pill btn-outline-danger btn-sm" type="button">Danger Button</button>
                    <button class="btn btn-pill btn-outline-light btn-sm txt-dark" type="button">Light Button</button>
                    
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Outline extra small buttons</h5>
                    <span>Add <code>.btn-pill</code>,<code>.btn-outline-*</code> and <code>.btn-xs</code> class for extra small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary btn-xs" type="button">Primary Button</button>
                    <button class="btn btn-pill btn-outline-secondary btn-xs" type="button">Secondary Button</button>
                    <button class="btn btn-pill btn-outline-success btn-xs" type="button">Success Button</button>
                    <button class="btn btn-pill btn-outline-info btn-xs" type="button">Info Button</button>
                    <button class="btn btn-pill btn-outline-warning btn-xs" type="button">Warning Button</button>
                    <button class="btn btn-pill btn-outline-danger btn-xs" type="button">Danger Button</button>
                    <button class="btn btn-pill btn-outline-light btn-xs txt-dark" type="button">Light Button</button>
                   
                  </div>
                </div>
</template>