<template>
                    <div class="card">
                  <div class="card-header pb-0">
                    <h5>Disabled outline buttons</h5>
                    <span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-outline-primary btn-air-primary disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-secondary btn-air-secondary disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-success btn-air-success disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-info btn-air-info disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-warning btn-air-warning disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-danger btn-air-danger disabled" type="button">Disabled</button>
                    <button class="btn btn-pill btn-outline-light btn-air-light disabled txt-dark" type="button">Disabled</button>
                  
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Gradien buttons</h5>
                    <span><code>.btn-pill</code>,<code>.btn-air-*</code>,<code>.btn-*</code> and <code>.btn-*-gradien</code> class for gradien button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-pill btn-air-primary btn-primary-gradien" type="button" title="btn btn-pill btn-air-primary btn-primary-gradien">Primary Button</button>
                    <button class="btn btn-pill btn-air-secondary btn-secondary-gradien" type="button" title="btn btn-pill btn-air-secondary btn-secondary-gradien">Secondary Button</button>
                    <button class="btn btn-pill btn-air-success btn-success-gradien" type="button" title="btn btn-pill btn-air-success btn-success-gradien">Success Button</button>
                    <button class="btn btn-pill btn-air-info btn-info-gradien" type="button" title="btn btn-pill btn-air-info btn-info-gradien">Info Button</button>
                    <button class="btn btn-pill btn-air-warning btn-warning-gradien" type="button" title="btn btn-pill btn-air-warning btn-warning-gradien">Warning Button</button>
                    <button class="btn btn-pill btn-air-danger btn-danger-gradien" type="button" title="btn btn-pill btn-air-danger btn-danger-gradien">Danger Button</button>
                    <button class="btn btn-pill btn-air-light btn-light-gradien txt-dark" type="button" title="btn btn-pill btn-air-light btn-light-gradien">Light Button</button>
                    
                  </div>
                </div>
</template>