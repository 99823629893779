<template>
    <div class="col-sm-12 col-md-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Tags with number</h5>
                  </div>
                  <div class="card-body">
                      <a class="badge badge-primary" href="#">1</a>
                      <a class="badge badge-secondary" href="#">2</a>
                      <a class="badge badge-success" href="#">3</a>
                      <a class="badge badge-info" href="#">4</a>
                      <a class="badge badge-warning text-dark" href="#">5</a>
                      <a class="badge badge-danger" href="#">6</a>
                      <a class="badge badge-light text-dark" href="#">7</a>
                      <a class="badge badge-dark" href="#">8</a></div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Pills with number</h5>
                  </div>
                  <div class="card-body">
                      <span class="badge rounded-pill badge-primary">1</span>
                      <span class="badge rounded-pill badge-secondary">2</span>
                      <span class="badge rounded-pill badge-success">3</span>
                      <span class="badge rounded-pill badge-info">4</span>
                      <span class="badge rounded-pill badge-warning text-dark">5</span>
                      <span class="badge rounded-pill badge-danger">6</span>
                      <span class="badge rounded-pill badge-light text-dark">7</span>
                      <span class="badge rounded-pill badge-dark">8</span></div>
                </div>
              </div>
</template>