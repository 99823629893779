<template>
     <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-primary ribbon-vertical-left"><i class="icofont icofont-love"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-secondary ribbon-vertical-left"><i class="icon-gift"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-success ribbon-vertical-left"><i class="icon-signal"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
            </div>
             <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-primary ribbon-vertical-right"><i class="fa fa-chain-broken"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-secondary ribbon-vertical-right"><i class="fa fa-ticket"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4 ">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-success ribbon-vertical-right"><i class="fa fa-taxi"></i></div>
                    <p>{{Lorem}}</p>
                  </div>
                </div>
              </div>
            </div>
</template>
<script>
export default {
  data(){
    return{
      Lorem:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    }
  }
}
</script>