<template>
     <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-primary"><h5>Border Left</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
         <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-r-secondary"><h5>Border Right</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
         <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-t-success"><h5>Border Top</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-b-info"><h5>Border Bottom</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-warning"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-danger"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
</template>