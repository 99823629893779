<template>
     <div class="row">
            <div class="col-xl-6 box-col-6 ">
                            <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                            <div class="info-block">
                              <h6>Zeta Admin is a full featured,premium bootstrap admin template.</h6><a href="#">zetaeducation.info/</a>
                              <p>zeta introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>3 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Cuba Admin is a full featured,premium bootstrap admin template.</h6><a href="#">cubaeducation.info/</a>
                              <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>3 stars</li>
                                  <li>780 votes</li>
                                  <li>Dance</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Viho Admin is a full featured,premium bootstrap admin template.</h6><a href="#">vihoeducation.info/</a>
                              <p>Viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>
                                    <ul class="rating">
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                    </ul>
                                  </li>
                                  <li>3 stars</li>
                                  <li>420 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Tivo Admin is a full featured,premium bootstrap admin template</h6><a href="#">tivoeducation.info/</a>
                              <p>Tivo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>
                                    <ul class="rating">
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                    </ul>
                                  </li>
                                  <li>3 stars</li>
                                  <li>390 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <nav aria-label="...">
                                <ul class="pagination pagination-primary">
                                  <li class="page-item disabled"><a class="page-link" href="javascript:void(0)" tabindex="-1">Previous</a></li>
                                  <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                                  <li class="page-item active"><a class="page-link" href="javascript:void(0)">2 <span class="sr-only"></span></a></li>
                                  <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                  <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                          <div class="col-xl-6 box-col-6 search-banner">
                            <div class="card">
                              <div class="blog-box blog-shadow">
                                <div class="blog-details">
                                  <p class="digits ">25 July 2023</p>
                                  <h4 class="">Accusamus et iusto odio dignissimos ducimus qui blanditiis.</h4>
                                  <ul class="blog-social digits ">
                                    <li><i class="icofont icofont-user"></i>Mark Jecno</li>
                                    <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</template>