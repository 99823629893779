<template>
     <div class="col-sm-12 col-md-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Sizing</h5>
                    </div>
                    <div class="card-body avatar-showcase">
                      <div class="avatars">
                        <div class="avatar"><img class="img-100 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-90 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-80 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-70 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-60 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-50 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-40 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-30 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                        <div class="avatar"><img class="img-20 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Status Indicator</h5>
                    </div>
                    <div class="card-body avatar-showcase">
                      <div class="avatars">
                        <div class="avatar"><img class="img-100 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-100 bg-primary"> </div>
                        </div>
                        <div class="avatar"><img class="img-90 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-90 bg-primary"></div>
                        </div>
                        <div class="avatar"><img class="img-80 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-80 bg-primary"></div>
                        </div>
                        <div class="avatar"><img class="img-70 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-70 bg-primary"></div>
                        </div>
                        <div class="avatar"><img class="img-60 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-60 bg-primary"></div>
                        </div>
                        <div class="avatar"><img class="img-50 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-50 bg-primary"> </div>
                        </div>
                        <div class="avatar"><img class="img-40 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-40 bg-primary"></div>
                        </div>
                        <div class="avatar"><img class="img-30 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#">
                          <div class="status status-30 bg-primary"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>