<template>
    <div class="col-sm-12">
                          <div class="card">
                            <div class="card-header social-header">
                              <h5 class="f-w-600">Hobbies and Interests<span class="pull-right"><i data-feather="more-vertical"><vue-feather type="more-vertical"></vue-feather> </i></span></h5>
                            </div>
                            <div class="card-body pt-0">
                              <div class="row details-about">
                                <div class="col-sm-6">
                                  <div class="your-details">
                                    <h6 class="f-w-600">Hobbies:</h6>
                                    <p>I like to ride the bike to work, swimming, and working out. I also like reading design magazines, go to museums, and binge watching a good tv show while it’s raining outside.</p>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="your-details your-details-xs">
                                    <h6 class="f-w-600">Favourite Music Bands / Artists:</h6>
                                    <p>Iron Maid, DC/AC, Megablow, The Ill, Kung Fighters, System of a Revenge.</p> </div>
                                </div>
                              </div>
                              <div class="row details-about">
                                <div class="col-sm-6">
                                  <div class="your-details">
                                    <h6 class="f-w-600">Favourite TV Shows:</h6>
                                    <p>Breaking Good, RedDevil, People of Interest, The Running Dead, Found, American Guy.</p></div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="your-details your-details-xs">
                                    <h6 class="f-w-600">Favourite Books:</h6>
                                    <p>The Crime of the Century, Egiptian Mythology 101, The Scarred Wizard, Lord of the Wings, Amongst Gods, The Oracle, A Tale of Air and Water.</p></div>
                                </div>
                              </div>
                              <div class="row details-about">
                                <div class="col-sm-6">
                                  <div class="your-details">
                                    <h6 class="f-w-600">Favourite Movies:</h6>
                                    <p>Idiocratic, The Scarred Wizard and the Fire Crown, Crime Squad Ferrum Man.</p></div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="your-details your-details-xs">
                                    <h6 class="f-w-600">Favourite Writers:</h6>
                                    <p>Martin T. Georgeston, Jhonathan R. Token, Ivana Rowle, Alexandr Platt, Marcus Roth.</p></div>
                                </div>
                              </div>
                              <div class="row details-about">
                                <div class="col-sm-6">
                                  <div class="your-details">
                                    <h6 class="f-w-600">Favourite Games:</h6>
                                    <p>The First of Us, Assassin’s Squad, Dark Assylum, NMAK16, Last Cause 4, Grand Snatch Auto.</p></div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="your-details your-details-xs">
                                    <h6 class="f-w-600">Other Interests:</h6>
                                    <p>Swimming, Surfing, Sviho Diving, Anime, Photography, Tattoos, Street Art.</p></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</template>
<script>
export default {
    
}
</script>