<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header bg-success d-flex justify-content-between align-items-center pb-0">
                    <h5>Success color Card</h5>
                  </div>
                  <div class="card-body bg-success">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text
                         of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-success">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div>
        <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header bg-danger d-flex justify-content-between align-items-center pb-0">
                    <h5>Danger color Card</h5>
                  </div>
                  <div class="card-body bg-danger">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                       unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text 
                       of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-danger">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div> 
        <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header bg-warning d-flex justify-content-between align-items-center border-bottom">
                    <h5>Warning color Card</h5>
                  </div>
                  <div class="card-body bg-warning">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text
                         of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-warning">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div>
         <div class="col-sm-12 col-xl-6 ">
          <div class="card ">
            <div class="card-header bg-primary"><h5>Primary Color Header</h5></div>
            <div class="card-body ">
              <p class="card-text mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
              the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.
              </p>
            </div><h6 class="card-footer mb-0">Card Footer</h6>
          </div>
        </div>
          <div class="col-sm-12 col-xl-6 ">
          <div class="card ">
            <div class="card-header "><h5>Primary Color Body</h5></div>
            <div class="card-body bg-primary">
              <p class="card-text mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
              the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.
              </p>
            </div><h6 class="card-footer mb-0">Card Footer</h6>
          </div>
        </div>
          <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center border-bottom">
                    <h5>Primary Color Footer</h5>
                  </div>
                  <div class="card-body">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text 
                        of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-primary">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div>
</template>