<template>
    <div class="col-xl-6 col-lg-12 col-md-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Padding Top</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.p-t-0 {
padding-top: 0px !important;
}
.p-t-5 {
padding-top: 5px !important;
}
.p-t-10 {
padding-top: 10px !important;
}
.p-t-15 {
padding-top: 15px !important;
}
.p-t-20 {
padding-top: 20px !important;
}
.p-t-25 {
padding-top: 25px !important;
}
.p-t-30 {
padding-top: 30px !important;
}
.p-t-35 {
padding-top: 35px !important;
}
.p-t-40 {
padding-top: 40px !important;
}
.p-t-45 {
padding-top: 45px !important;
}
.p-t-50 {
padding-top: 50px !important;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Padding Bottom</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.p-b-0 {
padding-bottom: 0px !important;
}
.p-b-5 {
padding-bottom: 5px !important;
}
.p-b-10 {
padding-bottom: 10px !important;
}
.p-b-15 {
padding-bottom: 15px !important;
}
.p-b-20 {
padding-bottom: 20px !important;
}
.p-b-25 {
padding-bottom: 25px !important;
}
.p-b-30 {
padding-bottom: 30px !important;
}
.p-b-35 {
padding-bottom: 35px !important;
}
.p-b-40 {
padding-bottom: 40px !important;
}
.p-b-45 {
padding-bottom: 45px !important;
}
.p-b-50 {
padding-bottom: 50px !important;
}</pre>
                  </div>
                </div>
              </div>
</template>