<template>
    <div class="col-sm-12 col-lg-6 col-xl-4 xl-50 col-md-12 box-col-6">
                <div class="card height-equal">
                  <div class="calender-widget">
                    <div class="cal-img"></div>
                    <div class="cal-date">
                      <h5>25<br>Apr</h5>
                    </div>
                    <div class="cal-desc text-center card-body">
                      <h6 class="f-w-600 explain">I must explain to you how? </h6>
                      <p class="custom-scrollbar">We are excited about continuing to build a complete ecosystem around value commerce, where we can serve Bharat consumers their shopping journey. Our mission is to become India’s value lifestyle leader.</p>
                    </div>
                  </div>
                </div>
              </div>
</template>
<script>
export default {
    
}
</script>