<template>
     <div class="row">
         <card1/>
         <card2/>
     </div>
</template>
<script>
import card1 from "../users/userCards/card1.vue"
import card2 from "../users/userCards/card2.vue"
export default {
    components:{
        card1,
        card2
    }
}
</script>