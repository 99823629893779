<template>
     <div class="col-sm-12 col-xl-12 ">
                <div class="card card-overflow">
                  <div class="card-header pb-0">
                    <h5>Dropdown Split Button</h5>
                  </div>
                  <div class="card-body dropdown-basic">
                    <div class="btn-group">
                      <button class="btn btn-primary" type="button">Primary Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-primary" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                            <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button class="btn btn-secondary" type="button">Secondary Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-secondary" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                             <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button class="btn btn-success" type="button">Success Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-success" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                             <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button class="btn btn-info" type="button">Info Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-info" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                             <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button class="btn btn-warning" type="button">Warning Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-warning" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                             <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button class="btn btn-danger" type="button">Danger Button</button>
                      <div class="dropdown separated-btn">
                        <button class="btn btn-danger" type="button"><i class="icofont icofont-arrow-down"></i></button>
                        <div class="dropdown-content">
                             <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>