<template>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Headings</h5><span>Use the included utility classes to recreate the small secondary heading text.  </span>
                  </div>
                  <div class="card-body typography">
                    <h3>Fancy display heading <br/><small class="text-muted">With faded secondary text</small></h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Lead</h5><span>Make a paragraph stand out by adding <code>.lead</code>.</span>
                  </div>
                  <div class="card-body">
                    <p class="lead">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Display headings</h5><span>Traditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider using a <strong>display heading</strong>—a larger, slightly more opinionated heading style.</span>
                  </div>
                  <div class="card-body typography">
                    <h1 class="display-1">Display 1</h1>
                    <h1 class="display-2">Display 2</h1>
                    <h1 class="display-3">Display 3</h1>
                    <h1 class="display-4">Display 4</h1>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Blockquotes</h5><span>Add a <code>&lt;footer class="blockquote-footer"&gt;</code> for identifying the source. Wrap the name of the source work in <code>&lt;cite&gt;</code>.</span>
                  </div>
                  <div class="card-body">
                    <p class="sub-title">Naming a source</p>
                    <div class="figure d-block">
                      <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                      </blockquote>
                      <div class="blockquote-footer">Someone famous in 
                        <cite title="Source Title">Source Title</cite>
                      </div>
                    </div>
                    <p class="sub-title">Alignment Center</p>
                    <div class="figure text-center d-block">
                      <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                      </blockquote>
                      <div class="blockquote-footer">Someone famous in 
                        <cite title="Source Title">Source Title</cite>
                      </div>
                    </div>
                    <p class="sub-title">Alignment Right</p>
                    <div class="figure text-end d-block">
                      <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                      </blockquote>
                      <div class="blockquote-footer">Someone famous in 
                        <cite title="Source Title">Source Title</cite>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Inline text elements</h5><span>Styling for common inline HTML5 elements.</span>
                  </div>
                  <div class="card-body">
                    <p>You can use the mark tag to 
                      <mark>highlight</mark> text.
                    </p>
                    <p>
                      <del>This line of text is meant to be treated as deleted text.</del>
                    </p>
                    <p>
                      <s>This line of text is meant to be treated as no longer accurate.</s>
                    </p>
                    <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
                    <p>
                      <u>This line of text will render as underlined</u>
                    </p>
                    <p><small>This line of text is meant to be treated as fine print.</small></p>
                    <p><strong>This line rendered as bold text.</strong></p>
                    <p><em>This line rendered as italicized text.</em></p>
                  </div>
                </div>
              </div>
</template>