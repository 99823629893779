<template>
  <div class="col-xl-12 recent-order-sec">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <h5>Recent Orders</h5>
          <table class="table table-bordernone">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>Value</th>
                <th>Rate</th>
                <th>       
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="media">
                    <img
                      class="img-fluid rounded-circle"
                      src="../../../assets/images/dashboard/product-1.png"
                      alt=""
                      data-original-title=""
                      title=""
                    />
                    <div class="media-body">
                      <router-link to="/ecommerce/indexProduct"
                        ><span>Yellow New Nike shoes</span></router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <p>16 August</p>
                </td>
                <td>
                  <p>54146</p>
                </td>
                <td>
                  <img
                    class="img-fluid"
                    src="../../../assets/images/dashboard/graph-1.png"
                    alt=""
                    data-original-title=""
                    title=""
                  />
                </td>
                <td>
                  <p>$210326</p>
                </td>
                <td>
                  <p>Done</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="media">
                    <img
                      class="img-fluid rounded-circle"
                      src="../../../assets/images/dashboard/product-2.png"
                      alt=""
                      data-original-title=""
                      title=""
                    />
                    <div class="media-body">
                      <router-link to="/ecommerce/indexProduct"
                        ><span>Sony Brand New Headphone</span></router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <p>2 October</p>
                </td>
                <td>
                  <p>32015</p>
                </td>
                <td>
                  <img
                    class="img-fluid"
                    src="../../../assets/images/dashboard/graph-2.png"
                    alt=""
                    data-original-title=""
                    title=""
                  />
                </td>
                <td>
                  <p>$548526</p>
                </td>
                <td>
                  <p>Done</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="media">
                    <img
                      class="img-fluid rounded-circle"
                      src="../../../assets/images/dashboard/product-3.png"
                      alt=""
                      data-original-title=""
                      title=""
                    />
                    <div class="media-body">
                       <router-link to="/ecommerce/indexProduct"
                        ><span>Beautiful Golden Tree plant</span></router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <p>21 March</p>
                </td>
                <td>
                  <p>12548</p>
                </td>
                <td>
                  <img
                    class="img-fluid"
                    src="../../../assets/images/dashboard/graph-3.png"
                    alt=""
                    data-original-title=""
                    title=""
                  />
                </td>
                <td>
                  <p>$589565</p>
                </td>
                <td>
                  <p>Done</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="media">
                    <img
                      class="img-fluid rounded-circle"
                      src="../../../assets/images/dashboard/product-4.png"
                      alt=""
                      data-original-title=""
                      title=""
                    />
                    <div class="media-body">
                       <router-link to="/ecommerce/indexProduct"
                        ><span>Marco M Kely Handbeg</span></router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <p>31 December</p>
                </td>
                <td>
                  <p>15495</p>
                </td>
                <td>
                  <img
                    class="img-fluid"
                    src="../../../assets/images/dashboard/graph-4.png"
                    alt=""
                    data-original-title=""
                    title=""
                  />
                </td>
                <td>
                  <p>$125424</p>
                </td>
                <td>
                  <p>Done</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="media">
                    <img
                      class="img-fluid rounded-circle"
                      src="../../../assets/images/dashboard/product-5.png"
                      alt=""
                      data-original-title=""
                      title=""
                    />
                    <div class="media-body">
                      <router-link to="/ecommerce/indexProduct"
                        ><span>Being Human Branded T-Shirt </span></router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <p>26 January</p>
                </td>
                <td>
                  <p>56625</p>
                </td>
                <td>
                  <img
                    class="img-fluid"
                    src="../../../assets/images/dashboard/graph-5.png"
                    alt=""
                    data-original-title=""
                    title=""
                  />
                </td>
                <td>
                  <p>$112103</p>
                </td>
                <td>
                  <p>Done</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
