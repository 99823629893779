<template>
     <button class="btn btn-primary-light" type="button"><a @click="logout" class="d-flex"><vue-feather type="log-out"></vue-feather>Log out</a></button>
</template>
<script>
 import firebase from 'firebase';
  import UserAuth from '../../auth/js/index';

export default {
    components:{
       
    },
     methods: {
      logout: function() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            UserAuth.Logout();
            this.$router.replace('/auth/login');
          });
      },
    },
}
</script>