<template>
      <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center pb-0">
                    <h5>Basic Card</h5>
                  </div>
                  <div class="card-body">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown 
                       printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                        the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  </div>
                </div>
              </div>
       <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center pb-0">
                    <h5><i class="icofont icofont-truck me-2"></i> Icon in Heading</h5>
                  </div>
                  <div class="card-body">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                       when an unknown printer took a galley of type and scrambled. Lorem Ipsum is 
                       simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                       the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  </div>
                </div>
              </div>
        <div class="col-sm-12 col-xl-6">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h5>Card sub Title</h5><span>Using the <a href="javascript:void(0)">card</a> component, you can extend the default collapse behavior to create an accordion.</span>
                  </div>
                  <div class="card-body">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled. Lorem Ipsum is
                         simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                         been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  </div>
                </div>
              </div>
        <div class="col-sm-12 col-xl-6">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h5>Card With Footer</h5>
                  </div>
                  <div class="card-body">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the the industry's standard dummy text ever.</p>
                  </div>
                  <div class="card-footer">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div>
        <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center pb-0 bg-primary">
                    <h5>Primary Color Card</h5>
                  </div>
                  <div class="card-body bg-primary">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text
                         of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-primary">
                    <h6 class="mb-0">Card Footer</h6>
                  </div>
                </div>
              </div>
           <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header bg-secondary d-flex justify-content-between align-items-center pb-0">
                    <h5>Secondary Color Card</h5>
                  </div>
                  <div class="card-body bg-secondary">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
                       unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has been the.</p>
                  </div>
                  <div class="card-footer bg-secondary">
                    <h6 class="mb-0 text-white">Card Footer</h6>
                  </div>
                </div>
              </div>
        
</template>