import ChartData from '../../components/chart/chart.json';

const state = {
	data : ChartData.data,
   
};


export default {
	namespaced: true,
	state,
	
};
