<template>
    <h6 class="mb-0">Upload Your Files</h6>
                        <form class="form theme-form">
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="col-form-label pt-0">Upload Cover Letter:<span class="font-danger">*</span></label>
                                <input class="form-control" type="file">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label class="col-form-label pt-0">Upload Your CV:<span class="font-danger">*</span></label>
                                <input class="form-control" type="file">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div>
                                <label class="col-form-label pt-0">Upload Recommendations:</label>
                                <input class="form-control" type="file">
                              </div>
                            </div>
                          </div>
                        </form>
</template>