<template>
    <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-info"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
         <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-primary"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-secondary border-2"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card">
            <div class="card-header b-l-primary border-3"><h5>Border color state</h5></div>
            <div class="card-body">
              <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card card-absolute card-overflow">
              <div class="card-header bg-primary"><h5>Absolute Style</h5></div>
              <div class="card-body">
                  <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled. Lorem Ipsum is 
                  simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                  been the industry's standard dummy text ever since the 1500s, when an unknown
                   printer took a galley of type and scrambled.</p>
                   </div>
                   </div>
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <div class="card card-absolute card-overflow">
              <div class="card-header bg-secondary"><h5>Color state</h5></div>
              <div class="card-body">
                  <p class="card-text mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled. Lorem Ipsum is 
                  simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                  been the industry's standard dummy text ever since the 1500s, when an unknown
                   printer took a galley of type and scrambled.</p>
                   </div>
                   </div>
        </div>
</template>