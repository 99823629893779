<template>
     <div class="card" id="default">
                  <div class="card-header pb-0">
                    <h5>Default buttons</h5>
                    <span>Bootstrap state buttons</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary" type="button">Primary Button</button>
                    <button class="btn btn-secondary" type="button">Secondary Button</button>
                    <button class="btn btn-success" type="button">Success Button</button>
                    <button class="btn btn-info" type="button">Info Button</button>
                    <button class="btn btn-warning" type="button">Warning Button</button>
                    <button class="btn btn-danger" type="button">Danger Button</button>
                    <button class="btn btn-light" type="button">Light Button</button>      
                  </div>
                </div>
                 <div class="card" id="large-btn">
                  <div class="card-header pb-0">
                    <h5>Large buttons</h5>
                    <span>Add <code>.btn-lg</code> class for large size buttons</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary btn-lg" type="button">Primary Button</button>
                    <button class="btn btn-secondary btn-lg" type="button">Secondary Button</button>
                    <button class="btn btn-success btn-lg" type="button">Success Button</button>
                    <button class="btn btn-info btn-lg" type="button">Info Button</button>
                    <button class="btn btn-warning btn-lg" type="button">Warning Button</button>
                    <button class="btn btn-danger btn-lg" type="button">Danger Button</button>
                    <button class="btn btn-light btn-lg" type="button">Light Button</button>  
                  </div>
                </div>
                 <div class="card" id="small-btn">
                  <div class="card-header pb-0">
                    <h5>Small buttons</h5>
                   <span>Add <code>.btn-sm</code> class for small size buttons</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary btn-sm" type="button">Primary Button</button>
                    <button class="btn btn-secondary btn-sm" type="button">Secondary Button</button>
                    <button class="btn btn-success btn-sm" type="button">Success Button</button>
                    <button class="btn btn-info btn-sm" type="button">Info Button</button>
                    <button class="btn btn-warning btn-sm" type="button">Warning Button</button>
                    <button class="btn btn-danger btn-sm" type="button">Danger Button</button>
                    <button class="btn btn-light btn-sm" type="button">Light Button</button>
                  </div>
                </div>
                 <div class="card" id="ex-small-btn">
                  <div class="card-header pb-0">
                    <h5>Extra Small buttons</h5>
                    <span>Add <code>.btn-xs</code> class for extra small size buttons</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary btn-xs" type="button">Primary Button</button>
                    <button class="btn btn-secondary btn-xs" type="button">Secondary Button</button>
                    <button class="btn btn-success btn-xs" type="button">Success Button</button>
                    <button class="btn btn-info btn-xs" type="button">Info Button</button>
                    <button class="btn btn-warning btn-xs" type="button">Warning Button</button>
                    <button class="btn btn-danger btn-xs" type="button">Danger Button</button>
                    <button class="btn btn-light btn-xs" type="button">Light Button</button>
                  </div>
                </div>
                 <div class="card" id="active-btn">
                  <div class="card-header pb-0">
                    <h5>Active Buttons</h5>
                    <span>Add <code>.active</code> class for active state</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary active" type="button">Active</button>
                    <button class="btn btn-secondary active" type="button">Active</button>
                    <button class="btn btn-success active" type="button">Active</button>
                    <button class="btn btn-info active" type="button">Active</button>
                    <button class="btn btn-warning active" type="button">Active</button>
                    <button class="btn btn-danger active" type="button">Active</button>
                    <button class="btn btn-light active txt-dark" type="button">Active</button>   
                  </div>
                </div>
                 <div class="card" id="disabled-btn">
                  <div class="card-header pb-0">
                    <h5>Disabled buttons</h5>
                    <span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary disabled" type="button">Disabled</button>
                    <button class="btn btn-secondary disabled" type="button">Disabled</button>
                    <button class="btn btn-success disabled" type="button">Disabled</button>
                    <button class="btn btn-info disabled" type="button">Disabled</button>
                    <button class="btn btn-warning disabled" type="button">Disabled</button>
                    <button class="btn btn-danger disabled" type="button">Disabled</button>
                    <button class="btn btn-light disabled" type="button">Disabled</button>
                    
                  </div>
                </div>
</template>