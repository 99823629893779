<template>
 
</template>
<script>
 import { mapGetters } from 'vuex';
export default {
     data() {
      return {

      }
    }
}
</script>
