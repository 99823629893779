<template>
     <div class="card">
                  <div class="card-header pb-0">
                    <h5>Default buttons</h5>
                    <span>Add <code>.btn-square</code> class for flat button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary" type="button">Primary Button</button>
                    <button class="btn btn-square btn-secondary" type="button">Secondary Button</button>
                    <button class="btn btn-square btn-success" type="button">Success Button</button>
                    <button class="btn btn-square btn-info" type="button">Info Button</button>
                    <button class="btn btn-square btn-warning" type="button">Warning Button</button>
                    <button class="btn btn-square btn-danger" type="button">Danger Button</button>
                    <button class="btn btn-square btn-light" type="button">Light Button</button>
                  
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Large buttons</h5>
                    <span>Add <code>.btn-square</code> and <code>.btn-lg</code> class for large button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary btn-lg" type="button">Primary Button</button>
                    <button class="btn btn-square btn-secondary btn-lg" type="button">Secondary Button</button>
                    <button class="btn btn-square btn-success btn-lg" type="button">Success Button</button>
                    <button class="btn btn-square btn-info btn-lg" type="button">Info Button</button>
                    <button class="btn btn-square btn-warning btn-lg" type="button">Warning Button</button>
                    <button class="btn btn-square btn-danger btn-lg" type="button">Danger Button</button>
                    <button class="btn btn-square btn-light btn-lg" type="button">Light Button</button>
                    
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Small buttons</h5>
                    <span>Add <code>.btn-square</code> and <code>.btn-sm</code> class for small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary btn-sm" type="button">Primary Button</button>
                    <button class="btn btn-square btn-secondary btn-sm" type="button">Secondary Button</button>
                    <button class="btn btn-square btn-success btn-sm" type="button">Success Button</button>
                    <button class="btn btn-square btn-info btn-sm" type="button">Info Button</button>
                    <button class="btn btn-square btn-warning btn-sm" type="button">Warning Button</button>
                    <button class="btn btn-square btn-danger btn-sm" type="button">Danger Button</button>
                    <button class="btn btn-square btn-light btn-sm" type="button">Light Button</button>
                  
                  </div>
                </div>
                 <div class="card">
                  <div class="card-header pb-0">
                    <h5>Extra Small buttons</h5>
                    <span>Add <code>.btn-square</code> and <code>.btn-xs</code> class for extra small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary btn-xs" type="button">Primary Button</button>
                    <button class="btn btn-square btn-secondary btn-xs" type="button">Secondary Button</button>
                    <button class="btn btn-square btn-success btn-xs" type="button">Success Button</button>
                    <button class="btn btn-square btn-info btn-xs" type="button">Info Button</button>
                    <button class="btn btn-square btn-warning btn-xs" type="button">Warning Button</button>
                    <button class="btn btn-square btn-danger btn-xs" type="button">Danger Button</button>
                    <button class="btn btn-square btn-light btn-xs" type="button">Light Button</button>
                    
                  </div>
                </div>
                 <div class="card">
                  <div class="card-header pb-0">
                    <h5>Active Buttons</h5>
                    <span>Add <code>.active</code> for active state</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary active" type="button">Active</button>
                    <button class="btn btn-square btn-secondary active" type="button">Active</button>
                    <button class="btn btn-square btn-success active" type="button">Active</button>
                    <button class="btn btn-square btn-info active" type="button">Active</button>
                    <button class="btn btn-square btn-warning active" type="button">Active</button>
                    <button class="btn btn-square btn-danger active" type="button">Active</button>
                    <button class="btn btn-square btn-light active txt-dark" type="button">Active</button>
                    
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Disabled buttons</h5>
                    <span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-square btn-primary disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-secondary disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-success disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-info disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-warning disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-danger disabled" type="button">Disabled</button>
                    <button class="btn btn-square btn-light disabled" type="button">Disabled</button>
                    
                  </div>
                </div>
</template>