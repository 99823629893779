<template>
<div>
    <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Inline Style</h5><span>For Create inline megaoption add <code>.mega-inline</code> class in form tag</span>
                  </div>
                  <div class="card-body megaoptions-border-space-sm">
                    <form class="mega-inline">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="media p-20">
                              <div class="radio radio-primary me-3">
                                <input id="radio19" type="radio" name="radio1" value="1" v-model="inlineStyle">
                                <label for="radio19"></label>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">COD<span class="badge badge-primary pull-right digits">50 INR</span></h6>
                                <p>Estimated 14-20 Day Shipping ( Duties end taxes may be due upon delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="media p-20">
                              <div class="radio radio-secondary me-3">
                                <input id="radio20" type="radio" name="radio1" value="2" v-model="inlineStyle">
                                <label for="radio20"></label>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">Fast<span class="badge badge-secondary pull-right digits">100 INR</span></h6>
                                <p>Estimated 1 Day Shipping ( Duties end taxes may be due upon delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="media p-20">
                              <div class="radio radio-secondary me-3">
                                <input id="radio21" type="radio" name="radio1" value="2" v-model="inlineStyle">
                                <label for="radio21"></label>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">Standard<span class="badge badge-secondary pull-right digits">80 INR</span></h6>
                                <p>Estimated 3 Day Shipping ( Duties end taxes may be due upon delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="media p-20">
                              <div class="radio radio-primary me-3">
                                <input id="radio22" type="radio" name="radio1" value="4" v-model="inlineStyle">
                                <label for="radio22"></label>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">Local <span class="badge badge-primary pull-right digits">Free</span></h6>
                                <p>Estimated 15 Day Shipping ( Duties end taxes may be due upon delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-end">
                    <button class="btn btn-primary m-r-15" type="submit">Submit</button>
                    <button class="btn btn-light" type="submit">Cancel</button>
                  </div>
                </div>
              </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            inlineStyle:1
        }
    }
}
</script>