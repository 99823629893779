<template>
     <div class="col-md-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Text Color</h5><span>You can Give text color by using txt-* class</span>
                  </div>
                  <div class="card-body">
                    <p class="txt-primary">This is Primary text You can archive this adding <code>.txt-primary</code> class</p>
                    <p class="txt-secondary">This is Secondary text You can archive this adding <code>.txt-secondary</code> class</p>
                    <p class="txt-success">This is Success text You can archive this adding <code>.txt-success</code> class</p>
                    <p class="txt-info">This is Info text You can archive this adding <code>.txt-info</code> class</p>
                    <p class="txt-warning">This is Warning text You can archive this adding <code>.txt-warning</code> class</p>
                    <p class="txt-danger">This is Danger text You can archive this adding <code>.txt-danger</code> class</p>
                    <p class="txt-dark">This is Dark text You can archive this adding <code>.txt-dark</code> class</p>
                    <p class="txt-primary">This is Primary text You can archive this adding <code>.txt-primary</code> class</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 listing ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Listing Typography</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">Unorder list</h6>
                        <ul>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                        </ul>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">Order list</h6>
                        <ol>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>
                            <ol>
                              <li>Lorem ipsum dolor sit amet</li>
                              <li>Lorem ipsum dolor sit amet</li>
                              <li>Lorem ipsum dolor sit amet</li>
                              <li>Lorem ipsum dolor sit amet</li>
                              <li>Lorem ipsum dolor sit amet</li>
                            </ol>
                          </li>
                          <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                        </ol>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">Order list</h6>
                        <dl>
                          <dt>Lorem ipsum dolor sit amet</dt>
                          <dd>- ipsum dolor sit amet</dd>
                          <dt>Lorem ipsum dolor sit amet</dt>
                          <dd>- ipsum dolor sit amet</dd>
                          <dt>Lorem ipsum dolor sit amet</dt>
                          <dd>- ipsum dolor sit amet</dd>
                          <dt>Lorem ipsum dolor sit amet</dt>
                          <dd>- ipsum dolor sit amet</dd>
                          <dt>Lorem ipsum dolor sit amet</dt>
                          <dd>- ipsum dolor sit amet</dd>
                        </dl>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">Fontawesome list</h6>
                        <ul>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        </ul>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">ICO Icon list</h6>
                        <ul>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        </ul>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <h6 class="sub-title">Themfy list</h6>
                        <ul>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                          <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

</template>