<template>
    
     <welcomeView/>
    
</template>
<script>
import welcomeView from "../default/welcomeView.vue"


export default {
    components:{
        welcomeView, 
       
    },
   
}
</script>