<template>
    <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Switch With color</h5>
                  </div>
                  <div class="card-body row switch-showcase">
                    <div class="col-sm-12">
                      <div class="media">
                        <label class="col-form-label m-r-10">Primary Color (Disabled)</label>
                        <div class="media-body text-end switch-lg icon-state">
                          <label class="switch">
                            <input type="checkbox" checked="" disabled=""><span class="switch-state bg-primary"></span>
                          </label>
                        </div>
                      </div>
                      <div class="media">
                        <label class="col-form-label m-r-10">Secondary Color</label>
                        <div class="media-body text-end switch-lg icon-state">
                          <label class="switch">
                            <input type="checkbox" checked=""><span class="switch-state bg-secondary"></span>
                          </label>
                        </div>
                      </div>
                      <div class="media">
                        <label class="col-form-label m-r-10">Success Color</label>
                        <div class="media-body text-end icon-state">
                          <label class="switch">
                            <input type="checkbox" checked=""><span class="switch-state bg-success"></span>
                          </label>
                        </div>
                      </div>
                      <div class="media">
                        <label class="col-form-label m-r-10">Info Color</label>
                        <div class="media-body text-end icon-state">
                          <label class="switch">
                            <input type="checkbox" checked=""><span class="switch-state bg-info"></span>
                          </label>
                        </div>
                      </div>
                      <div class="media">
                        <label class="col-form-label m-r-10">Warning Color</label>
                        <div class="media-body text-end switch-sm icon-state">
                          <label class="switch">
                            <input type="checkbox" checked=""><span class="switch-state bg-warning"></span>
                          </label>
                        </div>
                      </div>
                      <div class="media">
                        <label class="col-form-label m-r-10">Danger Color</label>
                        <div class="media-body text-end switch-sm icon-state">
                          <label class="switch">
                            <input type="checkbox" checked=""><span class="switch-state bg-danger"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>