<template>
    <div class="col-sm-12 col-xl-12 ">
                <div class="card card-overflow">
                  <div class="card-header pb-0">
                    <h5>Rounded Dropdown</h5>
                  </div>
                  <div class="card-body dropdown-basic">
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-primary btn-round" type="button">Primary Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                            <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-secondary btn-round" type="button">Secondary Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                              <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-success btn-round" type="button">Success Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                              <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-info btn-round" type="button">Info Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                              <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-warning txt-dark btn-round" type="button">Warning Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                              <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div class="btn-group mb-0">
                        <button class="dropbtn btn-danger btn-round" type="button">Danger Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                        <div class="dropdown-content">
                              <a href="#">Action</a>
                            <a href="#">Another Action</a>
                            <a href="#">Something Else Here</a>
                            <a href="#">Separated Link </a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>