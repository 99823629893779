<template>
      <div class="card">
                  <div class="card-header pb-0">
                    <h5>Disabled outline buttons</h5>
                    <span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled state</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-secondary disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-success disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-info disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-warning disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-danger disabled" type="button">Disabled</button>
                    <button class="btn btn-outline-light txt-dark disabled" type="button">Disabled</button>
                    
                  </div>
                </div>
                <div class="card" id="gradiant">
                  <div class="card-header pb-0">
                    <h5>Gradien buttons</h5>
                    <span>Add <code>.btn-*-gradien</code> class for gradien button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-primary-gradien" type="button">Primary Button</button>
                    <button class="btn btn-secondary-gradien" type="button">Secondary Button</button>
                    <button class="btn btn-success-gradien" type="button">Success Button</button>
                    <button class="btn btn-info-gradien" type="button">Info Button</button>
                    <button class="btn btn-warning-gradien" type="button">Warning Button</button>
                    <button class="btn btn-danger-gradien" type="button">Danger Button</button>
                   
                  </div>
                </div>
</template>