<template>
    <router-link to="/">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.png"
      alt=""
    />
  </router-link>
</template>
<script>

export default {
    
}
</script>