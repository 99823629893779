<template>
     <div class="col-sm-12 col-lg-6 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Alert With Icon inverse</h5><span>Use the <code>.inverse</code> class to quickly provide matching colored links within any alert</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary inverse alert-dismissible fade show" role="alert"><i class="icon-timer"></i>
                      <p>Your time Over after <b>5</b> miniute</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-secondary inverse alert-dismissible fade show" role="alert"><i class="icon-heart"><vue-feather type="heart"></vue-feather></i>
                      <p>Oh snap! Change a few things up and submit again.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-success inverse alert-dismissible fade show" role="alert"><i class="icon-thumb-up alert-center"><vue-feather type="thumbs-up"></vue-feather></i>
                      <p><b> Well done! </b>You successfully read this important alert message.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-info inverse alert-dismissible fade show" role="alert"><i class="icon-help-alt"><vue-feather type="help-circle"></vue-feather></i>
                      <p><b>welcome!</b> Start your day with some alerts.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-warning inverse alert-dismissible fade show" role="alert"><i class="icon-bell"><vue-feather type="bell"></vue-feather></i>
                      <p><b> Congratulation! </b>Your Product Added in Like List</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger inverse alert-dismissible fade show" role="alert"><i class="icon-thumb-down"><vue-feather type="thumbs-down"></vue-feather></i>
                      <p>Your  <b> Perfomance </b> is down on this week</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-light inverse alert-dismissible fade show" role="alert"><i class="icon-alert txt-dark"><vue-feather type="alert-triangle"></vue-feather></i>
                      <p>You can check in on some of those fields below.</p>
                      <button class="btn-close txt-light" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"><vue-feather type="alert-circle"></vue-feather></i>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              </div>
</template>