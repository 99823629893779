<template>
     <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Default list</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group">
                      <li class="list-group-item">Cras justo odio</li>
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item">Morbi leo risus</li>
                      <li class="list-group-item">Porta ac consectetur ac</li>
                      <li class="list-group-item">Vestibulum at eros</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Links and buttons</h5>
                  </div>
                  <div class="card-body">
                    <div class="list-group"><a class="list-group-item list-group-item-action active" href="javascript:void(0)">Cras justo odio</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Dapibus ac facilisis in</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Morbi leo risus</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Porta ac consectetur ac</a><a class="list-group-item list-group-item-action disabled" href="javascript:void(0)">Vestibulum at eros</a></div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Flush Style</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Cras justo odio</li>
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item">Morbi leo risus</li>
                      <li class="list-group-item">Porta ac consectetur ac</li>
                      <li class="list-group-item">Vestibulum at eros</li>
                      <li class="list-group-item">Cras justo odio</li>
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Contextual classes</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group">
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item list-group-item-primary">This is a primary list group item</li>
                      <li class="list-group-item list-group-item-secondary">This is a secondary list group item</li>
                      <li class="list-group-item list-group-item-success">This is a success list group item</li>
                      <li class="list-group-item list-group-item-danger">This is a danger list group item</li>
                      <li class="list-group-item list-group-item-warning">This is a warning list group item</li>
                      <li class="list-group-item list-group-item-info">This is a info list group item</li>
                      <li class="list-group-item list-group-item-light">This is a light list group item</li>
                      <li class="list-group-item list-group-item-dark">This is a dark list group item</li>
                    </ul>
                  </div>
                </div>
              </div>
</template>