<template>
    <div class="card" id="custom-state-button">
                  <div class="card-header pb-0">
                    <h5>Custom state buttons</h5>
                    <span>The <code>.btn</code> class used with <code>&lt;button&gt;</code>, <code>&lt;a&gt;</code> and <code>&lt;input&gt;</code> elements.</span>
                  </div>
                  <div class="card-body btn-showcase"><a class="btn btn-primary" href="javascript:void(0)" title="btn btn-primary" role="button">Link</a>
                    <input class="btn btn-secondary" type="button" value="Input" title="btn btn-secondary">
                    <input class="btn btn-success" type="submit" value="Submit" title="btn btn-success">
                    <button class="btn btn-info" type="submit">Button</button>
                  </div>
                </div>
                 <div class="card" id="outline-button">
                  <div class="card-header pb-0">
                    <h5>outline buttons</h5>
                    <span>Add <code>.btn-outline-*</code> class for border button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary" type="button">Primary Button</button>
                    <button class="btn btn-outline-secondary" type="button">Secondary Button</button>
                    <button class="btn btn-outline-success" type="button">Success Button</button>
                    <button class="btn btn-outline-info" type="button">Info Button</button>
                    <button class="btn btn-outline-warning" type="button">Warning Button</button>
                    <button class="btn btn-outline-danger" type="button">Danger Button</button>
                    <button class="btn btn-outline-light txt-dark" type="button">Light Button</button>   
                  </div>
                </div>
                 <div class="card" id="outline-bold-button">
                  <div class="card-header pb-0">
                    <h5>bold Border outline buttons</h5>
                   <span>Add <code>.btn-outline-*-2x</code> class for bold outline</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary-2x" type="button">Primary Button</button>
                    <button class="btn btn-outline-secondary-2x" type="button">Secondary Button</button>
                    <button class="btn btn-outline-success-2x" type="button">Success Button</button>
                    <button class="btn btn-outline-info-2x" type="button">Info Button</button>
                    <button class="btn btn-outline-warning-2x" type="button">Warning Button</button>
                    <button class="btn btn-outline-danger-2x" type="button">Danger Button</button>
                    <button class="btn btn-outline-light-2x txt-dark" type="button">Light Button</button> 
                  </div>
                </div>
                <div class="card" id="outline-large-button">
                  <div class="card-header pb-0">
                    <h5>outline Large buttons</h5>
                    <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-lg</code> class for large button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary btn-lg" type="button">Primary Button</button>
                    <button class="btn btn-outline-secondary btn-lg" type="button">Secondary Button</button>
                    <button class="btn btn-outline-success btn-lg" type="button">Success Button</button>
                    <button class="btn btn-outline-info btn-lg" type="button">Info Button</button>
                    <button class="btn btn-outline-warning btn-lg" type="button">Warning Button</button>
                    <button class="btn btn-outline-danger btn-lg" type="button">Danger Button</button>
                    <button class="btn btn-outline-light btn-lg txt-dark" type="button">Light Button</button>
                   
                  </div>
                </div>
                 <div class="card" id="outline-small-button">
                  <div class="card-header pb-0">
                    <h5>outline small buttons</h5>
                    <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-sm</code> class for small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary btn-sm" type="button">Primary Button</button>
                    <button class="btn btn-outline-secondary btn-sm" type="button">Secondary Button</button>
                    <button class="btn btn-outline-success btn-sm" type="button">Success Button</button>
                    <button class="btn btn-outline-info btn-sm" type="button">Info Button</button>
                    <button class="btn btn-outline-warning btn-sm" type="button">Warning Button</button>
                    <button class="btn btn-outline-danger btn-sm" type="button">Danger Button</button>
                    <button class="btn btn-outline-light btn-sm txt-dark" type="button">Light Button</button>
                  </div>
                </div>
                <div class="card" id="ex-outline-small-button">
                  <div class="card-header pb-0">
                    <h5>Outline extra small buttons</h5>
                    <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-xs</code> class for extra small button</span>
                  </div>
                  <div class="card-body btn-showcase">
                    <button class="btn btn-outline-primary btn-xs" type="button">Primary Button</button>
                    <button class="btn btn-outline-secondary btn-xs" type="button">Secondary Button</button>
                    <button class="btn btn-outline-success btn-xs" type="button">Success Button</button>
                    <button class="btn btn-outline-info btn-xs" type="button">Info Button</button>
                    <button class="btn btn-outline-warning btn-xs" type="button">Warning Button</button>
                    <button class="btn btn-outline-danger btn-xs" type="button">Danger Button</button>
                    <button class="btn btn-outline-light btn-xs txt-dark" type="button">Light Button</button>
                    
                  </div>
                </div>
              
</template>