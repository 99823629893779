<template>
     <div class="col-sm-12 col-md-6 ">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h5>Badges Example</h5>
                  </div>
                  <div class="card-body">
                    <h1>heading <span class="badge badge-primary">New</span></h1>
                    <h2>heading <span class="badge badge-primary">New</span></h2>
                    <h3>heading <span class="badge badge-primary">New</span></h3>
                    <h4>heading <span class="badge badge-primary">New</span></h4>
                    <h5>heading <span class="badge badge-primary">New</span></h5>
                    <h6>heading <span class="badge badge-primary">New</span></h6>
                  </div>
                </div>
              </div>
</template>