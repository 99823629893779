<template>
     <div class="col-sm-12 ">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Vertical alignment</h5>
                  </div>
                  <div class="card-body grid-showcase grid-align">
                    <p>Use flexbox alignment utilities to vertically and horizontally align columns.</p>
                    <div class="row">
                      <div class="col align-self-start"><span>One of three columns</span></div>
                      <div class="col align-self-center"><span>One of three columns</span></div>
                      <div class="col align-self-end"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-end">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                  </div>
                </div>
              </div>
</template>