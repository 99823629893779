<template>
    <div class="col-md-6">
                  <div class="card">
                    <div class="card-header pb-0"> <h5 class="card-title">Full Colored Variant</h5> </div>
                    <div class="card-body">
                      <div class="mb-2">
                        <label class="col-form-label">Primary Select</label>
                        <select class="form-control form-control-primary-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label">Secondary Select</label>
                        <select class="form-control form-control-secondary-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label">Success Select</label>
                        <select class="form-control form-control-success-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label">Info Select</label>
                        <select class="form-control form-control-info-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label">Warning Select</label>
                        <select class="form-control form-control-warning-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label">Danger Select</label>
                        <select class="form-control form-control-danger-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                      <div>
                        <label class="col-form-label">Inverse Select</label>
                        <select class="form-control form-control-inverse-fill btn-square" name="select">
                          <option value="opt1">Select One Value Only</option>
                          <option value="opt2">Type 2</option>
                          <option value="opt3">Type 3</option>
                          <option value="opt4">Type 4</option>
                          <option value="opt5">Type 5</option>
                          <option value="opt6">Type 6</option>
                          <option value="opt7">Type 7</option>
                          <option value="opt8">Type 8</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
</template>