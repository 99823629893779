<template>
    <div class="col-12 ">
                <div class="card">
                  <div class="card-header">
                    <h5>Outline Custom button group</h5>
                  </div>
                  <div class="card-body btn-group-showcase">
                    <div class="row">
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-primary" type="button">Left</button>
                          <button class="btn btn-primary" type="button">Middle</button>
                          <button class="btn btn-outline-primary" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-secondary" type="button">Left</button>
                          <button class="btn btn-secondary" type="button">Middle</button>
                          <button class="btn btn-outline-secondary" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-success" type="button">Left</button>
                          <button class="btn btn-success" type="button">Middle</button>
                          <button class="btn btn-outline-success" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-info" type="button">Left</button>
                          <button class="btn btn-info" type="button">Middle</button>
                          <button class="btn btn-outline-info" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-warning" type="button">Left</button>
                          <button class="btn btn-warning" type="button">Middle</button>
                          <button class="btn btn-outline-warning" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-danger" type="button">Left</button>
                          <button class="btn btn-danger" type="button">Middle</button>
                          <button class="btn btn-outline-danger" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-light txt-dark" type="button">Left</button>
                          <button class="btn btn-light" type="button">Middle</button>
                          <button class="btn btn-outline-light txt-dark" type="button">Right</button>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-6 col-sm-12 button-group-mb-sm">
                        <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
                          <button class="btn btn-outline-dark" type="button">Left</button>
                          <button class="btn btn-dark" type="button">Middle</button>
                          <button class="btn btn-outline-dark" type="button">Right</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>