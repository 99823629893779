<template>
    <div class="col-xl-4 xl-100 box-col-12 ">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>Articles</h5>
                          <p>How little experience or technical knowledge you currently have. The web is a very big place, and if you are the typical internet user, you probably visit several websites every day.</p>
                        </div><i data-feather="file-text"><vue-feather type="file-text"></vue-feather></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 xl-50 col-md-6 box-col-6 ">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>Knowledgebase</h5>
                          <p>A Website Designing course enables learners to essential designing and programming tools required to do the job efficiently. The curriculum is a blend of various themes.</p>
                        </div><i data-feather="book-open"><vue-feather type="book-open"></vue-feather></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 xl-50 col-md-6 box-col-6 ">
                  <div class="card bg-primary">
                    <div class="card-body">
                      <div class="media faq-widgets">
                        <div class="media-body">
                          <h5>Support</h5>
                          <p>The customer support industry is renaissance. Customer support as a specialty is coming into its own, offering companies a competitive advantage that’s to copy.</p>
                        </div><i data-feather="aperture"><vue-feather type="aperture"></vue-feather></i>
                      </div>
                    </div>
                  </div>
                </div>
</template>