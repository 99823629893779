<template>
     <div class="col-sm-12">
                          <div class="card">
                            <div class="card-header">
                              <h5>Viewed Your Profile</h5>
                            </div>
                            <div class="card-body avatar-showcase pt-0">
                              <div class="pepole-knows">
                                <ul>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/2.png"><span class="d-block f-w-600">Jason Borne</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/3.png"><span class="d-block f-w-600">Anna Mull</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/3.jpg"><span class="d-block f-w-600">Dion Cast</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/4.jpg"><span class="d-block f-w-600">Karlene Lex</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/8.jpg"><span class="d-block f-w-600">Vella Chism</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="add-friend text-center"><img class="img-60 img-fluid rounded-circle" alt="" src="../../../assets/images/user/10.jpg"><span class="d-block f-w-600">Wai Schalk</span>
                                      <button class="btn btn-primary btn-xs">Add Friend</button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-header">
                              <h5>Activity Log</h5>
                            </div>
                            <div class="card-body pt-0">
                              <div class="activity-log">
                                <div class="my-activity">
                                  <h6 class="f-w-600">Today</h6>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Comeren Diaz likes your photos.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Karlene Lex and Comeren Diaz now friends.</p>
                                  <p><span><i class="m-r-20" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i></span>Sarah Loren wrote on your timeline </p>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Johny Waston likes your post's.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Andew Jon became friends with Comeren Diaz.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Johny Waston became friends with Bucky Barnes.</p>
                                </div>
                                <div class="my-activity">
                                  <h6 class="f-w-600">25 December</h6>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Comeren Diaz likes your photos.</p>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Johny Waston likes your post's.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Karlene Lex and Comeren Diaz now friends.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Johny Waston became friends with Bucky Barnes.</p>
                                  <p><span><i class="m-r-20" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i></span>Sarah Loren wrote on your timeline </p>
                                  <p><span><i class="m-r-20" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i></span>Comeren Diaz wrote on your timeline</p>
                                </div>
                                <div class="my-activity">
                                  <h6 class="f-w-600">8 september</h6>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Comeren Diaz likes your photos.</p>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Johny Waston likes your post's.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Karlene Lex and Comeren Diaz now friends.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Johny Waston became friends with Bucky Barnes.</p>
                                  <p><span><i class="m-r-20" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i></span>Sarah Loren wrote on your timeline </p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Andew Jon became friends with Comeren Diaz.</p>
                                </div>
                                <div class="my-activity">
                                  <h6 class="f-w-600">6 June</h6>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Comeren Diaz likes your photos.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Karlene Lex and Comeren Diaz now friends.</p>
                                  <p><span><i class="m-r-20" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i></span>Sarah Loren wrote on your timeline </p>
                                  <p><span><i class="m-r-20" data-feather="thumbs-up"><vue-feather type="thumbs-up"></vue-feather></i></span>Johny Waston likes your post's.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Andew Jon became friends with Comeren Diaz.</p>
                                  <p><span><i class="m-r-20" data-feather="user-plus"><vue-feather type="user-plus"></vue-feather></i></span>Johny Waston became friends with Bucky Barnes.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</template>
<script>
export default {
    
}
</script>