<template>
     <Breadcrumbs main="Ecommerce" title="product"/>
             <prodectGrid/>
</template>
<script>
import prodectGrid from "./prodectGrid.vue"
export default {
    components:{
        prodectGrid
    }
}
</script>