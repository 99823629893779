<template>
    <div class="col-sm-12 col-lg-6 ">
              <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h5>Alert With Icon</h5><span>Just add Any icon before text</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary dark alert-dismissible fade show d-flex" role="alert"><i data-feather="clock"><vue-feather type="clock">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-secondary dark alert-dismissible fade show d-flex" role="alert"><i data-feather="heart"><vue-feather type="heart">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-success dark alert-dismissible fade show d-flex" role="alert"><i data-feather="thumbs-up"><vue-feather type="thumbs-up">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-info dark alert-dismissible fade show d-flex" role="alert"><i data-feather="help-circle"><vue-feather type="help-circle">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-warning dark alert-dismissible fade show d-flex" role="alert"><i data-feather="bell"><vue-feather type="bell">
                    </vue-feather></i>
                      <p class="mx-2">can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger dark alert-dismissible fade show d-flex" role="alert"><i data-feather="thumbs-down"><vue-feather type="thumbs-down">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-light dark alert-dismissible fade show d-flex" role="alert"><i data-feather="alert-triangle">
                      <vue-feather type="alert-triangle"></vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-dark dark alert-dismissible fade show d-flex" role="alert"><i data-feather="alert-circle"><vue-feather type="alert-circle">
                    </vue-feather></i>
                      <p class="mx-2"> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              </div>
</template>